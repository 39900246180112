export default [
  {
    path: '/mentor/register',
    name: 'mentorship-register',
    component: () => import('@/views/mentorship/register/Register.vue'),
    meta: {
      pageTitle: 'Mentorship List',
      breadcrumb: [
        {
          text: 'Register',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
  {
    path: '/mentor/schedule',
    name: 'mentorship-schedule',
    component: () => import('@/views/mentorship/schedule/MentorshipSchedule.vue'),
    meta: {
      pageTitle: 'Mentorship Schedule',
      breadcrumb: [
        {
          text: 'Schedule',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },

  {
    path: '/mentor/register-input',
    name: 'mentorship-register-input',
    component: () => import('@/views/mentorship/register/RegisterForm.vue'),
    meta: {
      pageTitle: 'Mentorship Register',
      breadcrumb: [
        {
          text: 'Register',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },

]
