export default [
    {
      path: '/universitas',
      name: 'universitas',
      component: () => import('@/views/universitas/Universitas.vue'),
      meta: {
        pageTitle: 'Universitas',
        breadcrumb: [
          {
            text: 'Universitas',
            active: true,
          },
        ],
        middleware: 'Auth',
      },
    },
    {
        path: '/fakultas',
        name: 'fakultas',
        component: () => import('@/views/fakultas/Fakultas.vue'),
        meta: {
          pageTitle: 'Fakultas',
          breadcrumb: [
            {
              text: 'Fakultas',
              active: true,
            },
          ],
          middleware: 'Auth',
        },
      },
      {
        path: '/jurusan',
        name: 'jurusan',
        component: () => import('@/views/jurusan/Jurusan.vue'),
        meta: {
          pageTitle: 'Jurusan',
          breadcrumb: [
            {
              text: 'Jurusan',
              active: true,
            },
          ],
          middleware: 'Auth',
        },
      },
  
  ]
  