
import gateway from './modules/gateway'
import auth from './modules/auth'
import pendidikan from './modules/pendidikan'
import master from './modules/master'
import member from './modules/member'
import mentorship from './modules/mentorship'
import log from './modules/log'
import platform from './modules/platfrom'
export default {
  gateway,
  auth,
  pendidikan,
  master,
  member,
  mentorship,
  log,
  platform

}
