import resource from '../resource'

const endpoints = {
  list: '/user/web/pendidikan',
  export: '/user/web/pendidikan/export',
  verify: '/user/web/pendidikan/verify',
  unverify: '/user/web/pendidikan/unverify'

}

export default {

  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data })
    return request
  },
  export: (data) => {
    const request = resource.get(`${endpoints.export}`, { params: data })
    return request
  },
  verify: (data) => {
    const request = resource.post(`${endpoints.verify}`, data)
    return request
  },
  unverify: (data) => {
    const request = resource.post(`${endpoints.unverify}`, data)
    return request
  },
}
