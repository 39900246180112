export default [
  {
    path: '/platform',
    name: 'platform',
    component: () => import('@/views/platform/Platform.vue'),
    meta: {
      pageTitle: 'Platform',
      breadcrumb: [
        {
          text: 'Platform',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
  {
    path: '/platformVersion',
    name: 'platformVersion',
    component: () => import('@/views/platform/PlatformVersion.vue'),
    meta: {
      pageTitle: 'Platform Version',
      breadcrumb: [
        {
          text: 'Platform',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },

]
