import { initialAbility } from '@/libs/acl/config'
import store from '@/store'

export default async function (context, middleware = []) {
  await store.dispatch('user/checkToken')

  const roleId = store.state.user.userLoggedIn?.role_id || null
  const checkLoggedIn = store.getters['user/isAuthenticated']

  // klo role id nya ga ada, tapi sudah login atau punya token (case nya seprti user yang mau ng-vote, ga punya role id), maka dapat ability[0]
  const ability = initialAbility[roleId] || initialAbility[0]
  const checkAbility = (() => {
    const obj = {}
    ability.forEach(item => {
      obj[item.subject] = {
        status: true,
        isFalseGoTo: () => context.next('/'),
      }
    })
    return obj
  })()

  const checker = {
    ...checkAbility,
    Auth: {
      status: checkLoggedIn,
      isFalseGoTo: () => context.next('/login'),
    },
  }

  if (middleware.length) {
    // handle ketika acl memiliki subject: all
    if (checker.Auth.status && checker.all?.status) {
      return context.next()
    }

    // check apakah middleware memenuhi checker
    for (let i = 0; i < middleware.length; i += 1) {
      const item = middleware[i]
      if (!checker[item].status) { // jika tidak terpenuhi.
        return checker[item].isFalseGoTo()
      }
    }

    return context.next()
  }

  return context.next('/login')
}
