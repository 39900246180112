// add here for more access page by role.

export const initialAbility = {
  0: [ // role id 0
    {
      action: 'read',
      subject: 'Auth',
    },
  ],
  1: [ // role id 1
    {
      action: 'read',
      subject: 'Auth',
    },
    {
      action: 'read',
      subject: 'Admin',
    },
  ],
  2: [ // role id 1
    {
      action: 'read',
      subject: 'Auth',
    },
    {
      action: 'read',
      subject: 'Admin',
    },
    {
      action: 'read',
      subject: 'Home',
    },
    {
      action: 'write',
      subject: 'Home',
    },
    {
      action: 'write',
      subject: 'Pendidikan',
    },
    {
      action: 'read',
      subject: 'Pendidikan',
    },
    {
      action: 'write',
      subject: 'Member',
    },
    {
      action: 'read',
      subject: 'Member',
    },
    {
      action: 'read',
      subject: 'MemberFakultas',
    },
    {
      action: 'read',
      subject: 'MemberDocument',
    },
    {
      action: 'read',
      subject: 'MemberEmail',
    },
    {
      action: 'write',
      subject: 'MemberVerify',
    },
    {
      action: 'read',
      subject: 'FetchMember',
    },
    {
      action: 'read',
      subject: 'Mentorship',
    },
    {
      action: 'write',
      subject: 'Mentorship',
    },
  ],
  7: [ // role id 1
    {
      action: 'read',
      subject: 'Auth',
    },
    {
      action: 'read',
      subject: 'Admin',
    },
    {
      action: 'write',
      subject: 'Member',
    },
    {
      action: 'read',
      subject: 'Member',
    },
    {
      action: 'read',
      subject: 'FetchMemberByFaculty',
    },
  ],
}

export const _ = undefined
