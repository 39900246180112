import {
  TOKEN_CURRENT,
  ROLE_ID,
  DIGITAL_SIGN,
} from '@/constants/index'
import storage from './storage'

const getUserDataFromStorage = () => ({
  tokenCurrent: storage.getStorage(TOKEN_CURRENT),
  roleId: storage.getStorage(ROLE_ID),
  digitalSign : storage.getStorage(DIGITAL_SIGN)
})

export default getUserDataFromStorage
