import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import getDataFromStorage from '@/helpers/getDataFromStorage'

const { tokenCurrent, roleId } = getDataFromStorage()

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
let newAbility = []
if (tokenCurrent) {
  newAbility = initialAbility[roleId] || initialAbility[0]
}

export default new Ability(newAbility)
