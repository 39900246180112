import resource from '../resource'

const endpoints = {
    logs: 'web/log',

}

export default {
    logs: (data) => {
        const body = {
          service:"user",
          url: endpoints.logs,
          method : "GET",
          data_body: data
        }
        return body
      },
}
