import resource from '../resource'

const endpoints = {
  platform: 'web/platform',
  platform_update: 'web/platform/update',
  platform_delete: 'web/platform/delete',
  platform_version: 'web/platform_version',
  platform_version_update: 'web/platform_version/update',
  platform_version_delete: 'web/platform_version/delete',

}

export default {
  platform: (data) => {
    const body = {
      service:"user",
      url: endpoints.platform,
      method : "GET",
      data_body: data
    }
    return body
  },
  create_platform: (data) => {
    const body = {
      service:"user",
      url: endpoints.platform,
      method : "POST",
      data_body: data
    }
    return body
  },
  update_platform: (data) => {
    const body = {
      service:"user",
      url: endpoints.platform_update,
      method : "POST",
      data_body: data
    }
    return body
  },
  delete_platform: (data) => {
    const body = {
      service:"user",
      url: endpoints.platform_delete,
      method : "POST",
      data_body: data
    }
    return body
  },

  platform_version: (data) => {
    const body = {
      service:"user",
      url: endpoints.platform_version,
      method : "GET",
      data_body: data
    }
    return body
  },
  create_platform_version: (data) => {
    const body = {
      service:"user",
      url: endpoints.platform_version,
      method : "POST",
      data_body: data
    }
    return body
  },
  update_platform_version: (data) => {
    const body = {
      service:"user",
      url: endpoints.platform_version_update,
      method : "POST",
      data_body: data
    }
    return body
  },
  delete_platform_version: (data) => {
    const body = {
      service:"user",
      url: endpoints.platform_version_delete,
      method : "POST",
      data_body: data
    }
  return body
  },
}
