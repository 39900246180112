import resource from '../resource'

const endpoints = {
  member: '/web/mentor/member',
  minat: '/web/mentor/minat',
  topic: '/web/mentor/topic',
  register: '/web/mentor/register',
  acceptRegis: '/web/mentor/accept',
  rejectRegis: '/web/mentor/reject',
  sequence: '/web/mentor/sequence',
  hide: '/web/mentor/hide',
  order: '/web/mentor/order',
  gmeet: '/web/mentor/gmeet',
  status: '/web/mentor/status'

}

export default {

  member: (data) => {
    const request = resource.get(`${endpoints.member}`, { params: data })
    return request
  },
  minat: (data) => {
    const request = resource.get(`${endpoints.minat}`, { params: data })
    return request
  },
  topic: (data) => {
    const request = resource.get(`${endpoints.topic}`, { params: data })
    return request
  },
  register: (data) => {
    const request = resource.post(`${endpoints.register}`, data)
    return request
  },
  order: (data) => {
    const request = resource.get(`${endpoints.order}`, { params: data })
    return request
  },
  gmeet: (data) => {
    const request = resource.post(`${endpoints.gmeet}`, data)
    return request
  },
  status: (data) => {
    const request = resource.post(`${endpoints.status}`, data)
    return request
  },
  registerList: (data) => {
    const request = resource.get(`${endpoints.register}`, { params: data })
    return request
  },
  acceptRegis: (data) => {
    const request = resource.post(`${endpoints.acceptRegis}`, data)
    return request
  },
  rejectRegis: (data) => {
    const request = resource.post(`${endpoints.rejectRegis}`, data)
    return request
  },
  hideRegis: (data) => {
    const request = resource.post(`${endpoints.hide}`, data)
    return request
  },
  sequence: (data) => {
    const request = resource.post(`${endpoints.sequence}`, data)
    return request
  },
  registerTest: (data) => {
    const body = {
      service:"mentorship",
      url: "web/mentor/register",
      method : "GET",
      data_body: data
    }
    return body
  },
}
