import resource from '../resource'

const endpoints = {
    fakultas: '/user/master/fakultas',
    jurusan: '/user/master/jurusan',

    role: '/user/web/role/master',

}

const master = {
    universitas: 'web/universitas',
    universitas_update: 'web/universitas/update',
    universitas_delete: 'web/universitas/delete',
    fakultas: 'web/fakultas',
    fakultas_update: 'web/fakultas/update',
    fakultas_delete: 'web/fakultas/delete',
    jurusan: 'web/jurusan',
    jurusan_update: 'web/jurusan/update',
    jurusan_delete: 'web/jurusan/delete',

}

export default {
    fakultas: () => {
        const request = resource.get(`${endpoints.fakultas}`)
        return request
    },
    jurusan: () => {
        const request = resource.get(`${endpoints.jurusan}`)
        return request
    },
    role: () => {
        const request = resource.get(`${endpoints.role}`)
        return request
    },

    universitas: (data) => {
        const body = {
          service:"user",
          url: master.universitas,
          method : "GET",
          data_body: data
        }
        return body
      },
    create_universitas: (data) => {
    const body = {
        service:"user",
        url: master.universitas,
        method : "POST",
        data_body: data
    }
    return body
    },
    update_universitas: (data) => {
    const body = {
        service:"user",
        url: master.universitas_update,
        method : "POST",
        data_body: data
    }
    return body
    },
    delete_universitas: (data) => {
    const body = {
        service:"user",
        url: master.universitas_delete,
        method : "POST",
        data_body: data
    }
    return body
    },

    fakultas: (data) => {
        const body = {
            service:"user",
            url: master.fakultas,
            method : "GET",
            data_body: data
          }
          return body
      },
    create_fakultas: (data) => {
    const body = {
        service:"user",
        url: master.fakultas,
        method : "POST",
        data_body: data
    }
    return body
    },
    update_fakultas: (data) => {
    const body = {
        service:"user",
        url: master.fakultas_update,
        method : "POST",
        data_body: data
    }
    return body
    },
    delete_fakultas: (data) => {
    const body = {
        service:"user",
        url: master.fakultas_delete,
        method : "POST",
        data_body: data
    }
    return body
    },

    jurusan: (data) => {
        const body = {
          service:"user",
          url: master.jurusan,
          method : "GET",
          data_body: data
        }
        return body
      },
    create_jurusan: (data) => {
    const body = {
        service:"user",
        url: master.jurusan,
        method : "POST",
        data_body: data
    }
    return body
    },
    update_jurusan: (data) => {
    const body = {
        service:"user",
        url: master.jurusan_update,
        method : "POST",
        data_body: data
    }
    return body
    },
    delete_jurusan: (data) => {
    const body = {
        service:"user",
        url: master.jurusan_delete,
        method : "POST",
        data_body: data
    }
    return body
    },

}
