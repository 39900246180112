import resource from '../resource'

const endpoints = {
    gateway: '/',

}

export default {
    encrypt: (data) => {
        const request = resource.post(`${endpoints.gateway}`, data)
        return request
      },
}
