import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import encryptions from '@/helpers/encryption'
import API from '@/api'
import Sodium from 'sodium-encryption'
import getDataFromStorage from '@/helpers/getDataFromStorage'
Vue.mixin({
  methods: {
    toastError(title = 'Ups') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
    },
    handleMsgSuccess(title = 'Berhasil menyimpan') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    sweetAlertSuccess(msg = 'Berhasil') {
      this.$swal({
        title: 'Success!',
        text: msg,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    handleMsgErrors(error, title = 'Gagal menyimpan') {
      if (error.response.status === 422) {
        if (error.response.data.error_type === 'validation') {
          const self = this
          error.response.data.errors.forEach((err, i) => {
            setTimeout(() => {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message,
                  icon: 'BellIcon',
                  variant: 'danger',
                  setTimeout: '5000',
                },
              })
            }, 3000 * i)
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
              setTimeout: '3000',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            variant: 'danger',
            setTimeout: '3000',
          },
        })
      }
    },
    async encryptRequest(requestBody) {
      var response = null
      try {
      var bodyData = this.buildHeader(requestBody)
      console.log(bodyData)
      var encryptData = await this.encrypt(bodyData)
      var requestData = {
        data:encryptData
      }
      console.log(requestData)
      const { data } = await API.gateway.encrypt(requestData)
      console.log(data)
      const decryptData = await this.decrypt(data)
      console.log(decryptData)
      response =  JSON.parse(decryptData)
      } catch (error) {
       
        const decryptData = await this.decrypt(error.response.data)
        console.log(decryptData)
      }
      finally {
        return response
      }
      
    },
    async encrypt(data) {
      var json_encode = JSON.stringify(data)
      let digital_sign = getDataFromStorage().digitalSign
      var nonce = Buffer.from(digital_sign.random_key, 'hex')
      var message = Buffer.from(json_encode)
      var localSecret = Buffer.from(digital_sign.client_private_key, 'hex')
      var serverPublic = Buffer.from(digital_sign.gateway_public_key, 'hex')
      var key = Sodium.scalarMultiplication(localSecret, serverPublic)
      var box = Sodium.encrypt(message, nonce, key)
      var chipper = box.toString('hex')

      return chipper
    },
    async decrypt(data) {
      var encrypted = Buffer.from(data, 'hex');
      let digital_sign = getDataFromStorage().digitalSign
      var nonce = Buffer.from(digital_sign.random_key, 'hex')
      var localSecret = Buffer.from(digital_sign.gateway_private_key, 'hex')
      var serverPublic = Buffer.from(digital_sign.client_public_key, 'hex')
      var key = Sodium.scalarMultiplication(localSecret, serverPublic)
      var dec = Sodium.decrypt(encrypted, nonce, key)
      return dec.toString()
    },
    buildHeader(data) {
      const token = getDataFromStorage().tokenCurrent
      const headers = {}
      
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }
  
      const {
        VUE_APP_X_UIC_VERSION,
        VUE_APP_X_API_VERSION,
        VUE_APP_X_UIC_PLATFORM,
        VUE_APP_X_UIC_DEVICE,
        // VUE_APP_X_UIC_CLIENTKEY,
      } = process.env
  
      headers['X-UIC-VERSION'] = VUE_APP_X_UIC_VERSION
      headers['X-API-VERSION'] = VUE_APP_X_API_VERSION
      headers['X-UIC-PLATFORM'] = VUE_APP_X_UIC_PLATFORM
      headers['X-UIC-DEVICE'] = VUE_APP_X_UIC_DEVICE
      data.data_headers = headers
      return data
    }
    
  },
})
